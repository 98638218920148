import Image from 'next/image';
import Link from 'next/link';
import { PortableText } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { urlForImage } from '@lib/sanity/image';

type Props = {
  data?: {
    bgColor: string;
    breadcrumbItems: {
      label: string;
      href?: string;
    }[];
    breadcrumbsColor: string;
    largeImage: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
    mobileImage: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
    title: PortableTextBlock[];
    titleColor: string;
  };
};

export default function HeadlineWithBreadcrumbs({ data }: Props): JSX.Element {
  const breadcrumbs = data?.breadcrumbItems ?? [];
  const largeImageUrl = data?.largeImage ? urlForImage(data.largeImage).url() : '';
  const mobileImageUrl = data?.mobileImage ? urlForImage(data.mobileImage).url() : '';

  return (
    <div className={`relative flex flex-col justify-center md:flex-row w-full ${data?.bgColor ?? 'bg-white'}`}>
      <div
        className={`relative w-full ${largeImageUrl ? 'h-[13.75rem] md:h-[15rem] lg:h-[18.75rem]' : 'h-[10rem] md:h-[9.6875rem]]'}`}
      >
        {largeImageUrl ? (
          <>
            <source media="(max-width: 960px)" srcSet={mobileImageUrl} />
            <source media="(min-width: 1200px)" srcSet={largeImageUrl} />
            <Image
              alt={data?.title ? portableTextBlocksToText(data.title) : 'Fuca'}
              blurDataURL={data?.largeImage.lqip}
              fill
              placeholder="blur"
              priority
              sizes="100vw"
              src={largeImageUrl}
              style={{
                objectFit: 'cover',
                filter: 'brightness(0.8)',
              }}
            />
          </>
        ) : null}
      </div>
      <div className="flex flex-col absolute top-0 right-0 bottom-0 left-0 items-center px-4 md:px-10 lg:px-16">
        <div className="flex flex-wrap justify-start items-center h-6 mt-3 lg:flex-nowrap w-full max-w-screen-lg">
          <MuiBreadcrumbs
            aria-label="breadcrumb"
            className="text-xs"
            separator={<NavigateNextIcon className={`text-[0.8125rem] ${data?.breadcrumbsColor ?? 'text-white'}`} />}
          >
            <Link className={`text-[0.8125rem] font-semibold ${data?.breadcrumbsColor ?? 'text-white'}`} href="/">
              Home
            </Link>
            {breadcrumbs.map((item) => {
              return item.href ? (
                <Link
                  className={`text-[0.8125rem] font-semibold ${data?.breadcrumbsColor ?? 'text-white'}`}
                  href={item.href ?? '/'}
                  key={item.label}
                >
                  {item.label}
                </Link>
              ) : (
                <p className={`text-[0.8125rem] ${data?.breadcrumbsColor ?? 'text-white'}`} key={item.label}>
                  {item.label}
                </p>
              );
            })}
          </MuiBreadcrumbs>
        </div>
        <h1
          className={`my-auto w-full max-w-screen-lg pb-6 font-display font-semibold leading-fluid-md ${largeImageUrl ? 'text-fluid-md text-left' : 'text-fluid-sm text-center'} ${data?.titleColor ?? 'text-white'}`}
        >
          <PortableText value={data?.title ?? []} />
        </h1>
      </div>
    </div>
  );
}

function portableTextBlocksToText(blocks: PortableTextBlock[]): string {
  return blocks
    .map((block) => {
      if (block._type !== 'block' || !block.children.length) return '';

      return block.children.map((child) => child.text as string).join('');
    })
    .join(' ');
}
