type SectionProps = {
  bgColor?: string;
  children: React.ReactNode;
};

export default function Section({ bgColor, children }: SectionProps): JSX.Element {
  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-6 sm:py-8 lg:py-10 ${bgColor ?? 'bg-white'}`}
    >
      <div className="flex w-full justify-center px-4 md:px-10 lg:px-16">{children}</div>
    </section>
  );
}
