import RatingIcon from '@mui/material/Rating';
import { useTranslation, type TranslationsDictionary } from '@lib/i18n';

type Props = {
  data: {
    bgColor?: string;
    rating: string;
    reviewsCount?: number;
  };
  translationsDictionary: TranslationsDictionary;
};

export default function Rating({ data, translationsDictionary }: Props): JSX.Element {
  const t = useTranslation(translationsDictionary);

  return (
    <section
      className={`flex w-full justify-center px-4 md:px-10 py-[0.6875rem] lg:px-16 sm:py-6 lg:py-8 ${data.bgColor ?? 'bg-accent-2'}`}
    >
      <div className="flex flex-wrap justify-center items-center text-[0.8125rem] leading-[1.125rem] lg:flex-nowrap w-full max-w-screen-xl">
        <span className="pt-1 md:text-base font-semibold">{t('rating.excellent')}</span>&nbsp;
        <span className="pt-1 pr-2 md:text-base font-light">{data.rating}</span>
        <RatingIcon className="text-[1.25rem] lg:text-3xl" name="read-only" readOnly value={5} />
        <span className="w-full sm:w-auto sm:pt-1 px-2 md:text-base text-center font-light">
          {t('rating.basedOn')} <span className="font-semibold">{data.reviewsCount}</span> {t('rating.reviews')}
        </span>
      </div>
    </section>
  );
}
