'use client';

import { createElement } from 'react';
import type { TranslationsDictionary } from '@lib/i18n';
import ContactForm from './contact-form';
import Faqs from './faqs';
import FeaturedProducts from './featured-products';
import GroupedFaqs from './grouped-faqs';
import HeadlineWithBreadcrumbs from './headline-with-breadcrumbs';
import Hero from './hero';
import PostsLatest from './posts-latest';
import PostsGrid from './posts-grid';
import Rating from './rating';
import Separator from './separator';
import StoreLocator from './store-locator';
import TextAndDescription from './text-and-description';
import TextAndIconsHorizontal from './text-and-icons-horizontal';
import TextAndIconsVertical from './text-and-icons-vertical';
import TextAndImage from './text-and-image';
import TextAndImageCarousel from './text-and-image-carousel';
import TextAndVerticalCards from './text-and-vertical-cards';
import TextBlock from './text-block';
import YoutubeVideos from './youtube-videos';

type ComponentProps = {
  data: {
    _type: string;
  };
  locale: string;
  translationsDictionary: TranslationsDictionary;
};

const componentTypes = {
  contactForm: ContactForm,
  faqs: Faqs,
  featuredProducts: FeaturedProducts,
  groupedFaqs: GroupedFaqs,
  headlineWithBreadcrumbs: HeadlineWithBreadcrumbs,
  hero: Hero,
  latestPosts: PostsLatest,
  postsGrid: PostsGrid,
  rating: Rating,
  separator: Separator,
  storeLocator: StoreLocator,
  textAndDescription: TextAndDescription,
  textAndImage: TextAndImage,
  textAndImageCarousel: TextAndImageCarousel,
  textAndIconsHorizontal: TextAndIconsHorizontal,
  textAndIconsVertical: TextAndIconsVertical,
  textAndVerticalCards: TextAndVerticalCards,
  textBlock: TextBlock,
  youtubeVideos: YoutubeVideos,
};

export default function Component({ data, locale, translationsDictionary }: ComponentProps): JSX.Element | null {
  // @ts-expect-error -- this would require a type for each component, and we don't need that complexity
  const component = componentTypes[data._type];

  if (!component) {
    return null;
  }

  return createElement(component, {
    data,
    locale,
    translationsDictionary,
  });
}
