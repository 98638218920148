import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import type { TranslationsDictionary } from '@lib/i18n';
import { useTranslation } from '@lib/i18n';

type BuyNowModalProps = {
  buyNowURL: string;
  onClose: () => void;
  translationsDictionary: TranslationsDictionary;
};

export default function BuyNowModal({
  buyNowURL,
  onClose,
  translationsDictionary,
}: BuyNowModalProps): JSX.Element | null {
  const t = useTranslation(translationsDictionary);
  const [isCouponCodeCopied, setIsCouponCodeCopied] = useState(false);
  const [error, setError] = useState('');

  function handleCopyToClipboard(): void {
    navigator.clipboard
      .writeText(t('coupon.code'))
      .then(() => {
        setIsCouponCodeCopied(true);
      })
      .catch(() => {
        setError(t('coupon.copyError'));
      });
  }

  return (
    <Dialog
      aria-describedby="buy-now-dialog-description"
      aria-labelledby="buy-now-dialog-title"
      onClose={onClose}
      open
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '350px',
          '@media (min-width:600px)': {
            maxWidth: '470px',
          },
        },
      }}
    >
      <IconButton
        aria-label={t('header.closeMenu')}
        className="text-black absolute top-0 right-0 p-1 sm:p-3"
        onClick={onClose}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <DialogTitle
        className="mt-3 sm:mt-6 w-full text-center text-primary text-[1.375rem] leading-[1.75rem] font-display font-normal"
        id="buy-now-dialog-title"
      >
        {t('storeLocator.buyOnline')} {t('storeLocator.freeShipping')}
      </DialogTitle>
      <DialogContent className="flex items-center text-nowrap justify-center text-center text-[0.8125rem] leading-[1.1875rem] p-0">
        <strong>{t('coupon.shortInstructions1')}</strong>
        <span className="ml-1">{t('coupon.shortInstructions2')}</span>
      </DialogContent>
      <DialogActions className="flex flex-col">
        <div className="flex flex-row items-center justify-center gap-2 w-full h-full p-2 bg-white rounded-lg">
          <Button
            className="px-3 py-1 text-base leading-[1.625rem] bg-white text-black border-dashed border-primary border-[1px] rounded-lg hover:text-white"
            disableElevation
            onClick={handleCopyToClipboard}
            variant="contained"
          >
            {t('coupon.code')}
          </Button>
          <Button
            className={`px-4 py-1 text-sm leading-[1.625rem] font-semibold ${isCouponCodeCopied ? 'text-[#3A8B3C]' : ''}`}
            disableElevation
            onClick={handleCopyToClipboard}
            startIcon={isCouponCodeCopied ? <CheckIcon /> : <ContentCopyOutlinedIcon />}
            variant="text"
          >
            {isCouponCodeCopied ? t('coupon.copied') : t('coupon.copy')}
          </Button>
          {error ? <p className="text-red-500">{error}</p> : null}
        </div>
        <div className="flex flex-col px-6 mb-6 sm:flex-col-reverse sm:items-center">
          <Button
            className="items-center whitespace-nowrap text-white mt-3 py-3.5 sm:py-3 sm:px-20 text-base sm:leading-[1.5625rem] font-semibold"
            color="primary"
            disableElevation
            href={buyNowURL}
            startIcon={<ShoppingCartOutlinedIcon className="w-6 h-6" />}
            variant="contained"
          >
            {t('storeLocator.buyNow')}
          </Button>
          <p className="mt-2 w-full text-center text-[0.6875rem] leading-[0.9375rem]">{t('coupon.disclaimer')}</p>
        </div>
      </DialogActions>
    </Dialog>
  );
}
