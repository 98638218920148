'use client';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { PortableText } from '@portabletext/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import type { PortableTextBlock } from '@portabletext/types';
import { urlForImage } from '@lib/sanity/image';
import 'swiper/css';
import 'swiper/css/pagination';

type CardsCarouselProps = {
  items?: {
    _key: string;
    cardTitle: string;
    description: PortableTextBlock;
    image?: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
  }[];
};
export default function CardsCarousel({ items }: CardsCarouselProps): JSX.Element {
  if (!items || items.length === 0) {
    return <div className="hidden">No items have been associated to this component</div>;
  }

  return (
    <Swiper
      breakpoints={{
        620: {
          pagination: {
            enabled: items.length > 1,
          },
          slidesPerView: items.length >= 2 ? 2 : items.length,
          spaceBetween: 35,
        },
        1300: {
          centeredSlides: items.length <= 3,
          centeredSlidesBounds: items.length <= 3,
          pagination: {
            enabled: items.length > 3,
          },
          slidesPerView: items.length >= 4 ? 4 : items.length,
          spaceBetween: 30,
        },
      }}
      className="w-full"
      loop={false}
      modules={[Pagination]}
      pagination={{
        clickable: true,
        enabled: true,
      }}
      slidesPerView={1}
      spaceBetween={20}
    >
      {items.map((item, index) => {
        return (
          <SwiperSlide key={item.cardTitle}>
            <Card elevation={0}>
              <div className="relative">
                <CardMedia
                  className="h-[16rem] rounded-lg"
                  image={item.image ? urlForImage(item.image).url() : ''}
                  title={item.cardTitle}
                />
                <div className="absolute bottom-8 left-0 right-0 text-center text-white p-2">
                  <p className="font-display text-2xl">
                    <span className="text-red text-4xl text-[#faff03]">{index + 1}. </span>
                    {item.cardTitle}
                  </p>
                </div>
              </div>
              <CardContent>
                <div className="text-[0.9375rem] text-center text-black leading-6 line-clamp-6">
                  <PortableText value={item.description} />
                </div>
              </CardContent>
            </Card>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
