/* eslint-disable @typescript-eslint/no-misused-promises -- don't know why get this error in handleSubmit*/
'use client';

import Link from 'next/link';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { AlertColor } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
// eslint-disable-next-line import/no-named-as-default -- we are supposed to import it like this as per the docs
import ReCAPTCHA from 'react-google-recaptcha';
import type { TranslationsDictionary } from '@lib/i18n';
import { useTranslation } from '@lib/i18n';
import { handleContactForm } from '@lib/actions/contact';
import { EmailIcon, PhoneIcon } from '@public/icons';
import ContactSuccessMessage from './contact-success-message';

declare global {
  interface Window {
    recaptchaOptions?: { [key: string]: unknown };
  }
}

type Props = {
  data: {
    _id: string; // this is the ID of the document (not of the component), obtained in the query by using ^._id
    _type: string;
    recipientEmail: string;
    customerServiceEmail: string;
    customerServiceNumber: string;
  };
  translationsDictionary: TranslationsDictionary;
  locale: string;
  slug: string | string[];
};

export type ContactFormData = {
  name: string;
  email: string;
  phone: string;
  message: string;
  tos: boolean;
};

const textFieldProps = {
  input: { className: 'text-[0.875rem] leading-5' },
};

export default function ContactForm({ data, translationsDictionary, locale }: Props): JSX.Element {
  const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '';
  const t = useTranslation(translationsDictionary);
  const [showAlert, setShowAlert] = useState({ show: false, type: 'success' });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { register, handleSubmit, formState, reset } = useForm<ContactFormData>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      tos: false,
    },
  });
  const { errors } = formState;

  useEffect(() => {
    window.recaptchaOptions = {
      enterprise: true,
    };
  }, []);

  const onSubmit = async (contactData: ContactFormData): Promise<void> => {
    const recaptchaCode = await recaptchaRef.current?.executeAsync();
    const { type } = await handleContactForm(
      { localeId: locale, documentId: data._id, token: recaptchaCode ?? undefined },
      contactData
    );

    if (type === 'error') {
      setShowAlert({ show: true, type: 'error' });
    }

    setShowAlert({ show: true, type: 'success' });
    reset();
  };

  return (
    <section className="flex flex-col w-full justify-center items-center pt-6 lg:py-12">
      <div className="flex w-full justify-center px-6 md:px-10 lg:px-16">
        <div className="flex flex-col sm:flex-row gap-y-6 gap-x-[5.125rem] justify-center w-full max-w-none lg:max-w-[62.1875rem]">
          {showAlert.show && showAlert.type === 'success' ? (
            <ContactSuccessMessage translationsDictionary={translationsDictionary} />
          ) : (
            <div className="flex flex-col">
              <h5 className="text-2xl text-black font-display leading-[1.625rem] font-normal">
                {t('contact.contactFormTitle')}
              </h5>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} size="invisible" />
                <div className="flex flex-col mt-4">
                  <TextField
                    className="mb-2"
                    id="filled-basic"
                    label={t('contact.name')}
                    variant="filled"
                    {...register('name', { required: t('contact.isRequired') })}
                    InputProps={textFieldProps.input}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                  />
                  <TextField
                    className="mb-2"
                    id="filled-basic"
                    label={t('contact.email')}
                    variant="filled"
                    {...register('email', { required: t('contact.isRequired') })}
                    InputProps={textFieldProps.input}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                  />
                  <TextField
                    className="mb-2"
                    id="filled-basic"
                    label={t('contact.phone')}
                    variant="filled"
                    {...register('phone', { required: t('contact.isRequired') })}
                    InputProps={textFieldProps.input}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone?.message}
                  />
                  <TextField
                    className="mb-4 lg:mb-2"
                    id="filled-basic"
                    label={t('contact.message')}
                    minRows={4}
                    multiline
                    variant="filled"
                    {...register('message', { required: t('contact.isRequired') })}
                    InputProps={textFieldProps.input}
                    error={Boolean(errors.message)}
                    helperText={errors.message?.message}
                  />
                  <FormControl component="fieldset" error={Boolean(errors.tos)}>
                    <FormGroup className="mb-4 lg:mb-0">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color={errors.tos?.message ? 'error' : 'primary'}
                            inputProps={{ className: 'border border-[#ADADA9]' }}
                            sx={{
                              color: errors.tos?.message ? 'var(--mui-palette-error-main)' : '',
                            }}
                          />
                        }
                        label={
                          <span>
                            {t('contact.tosMsg1')}&nbsp;
                            <Link href="/politica-de-privacidad" target="_blank">
                              {t('contact.tosMsg2')}
                            </Link>
                            &nbsp;
                            {t('contact.tosMsg3')}
                          </span>
                        }
                        sx={{
                          color: errors.tos?.message ? 'var(--mui-palette-error-main)' : '',
                        }}
                        {...register('tos', { required: t('contact.isRequired') })}
                        classes={{ label: 'text-[0.75rem] leading-[1.125rem]' }}
                      />
                    </FormGroup>
                    <FormHelperText>{errors.tos?.message}</FormHelperText>
                  </FormControl>
                  <div className="mb-4 lg:mt-1">
                    <Button color="primary" disableElevation type="submit" variant="contained">
                      {t('contact.send')}
                    </Button>
                  </div>
                  {showAlert.show && showAlert.type === 'error' ? (
                    <Alert icon={<CheckIcon fontSize="inherit" />} severity={showAlert.type as AlertColor}>
                      {t('contact.error')}
                    </Alert>
                  ) : null}
                  <div className="mt-2 text-xxs text-[#797979] leading-4">{t('contact.legalNotice')}</div>
                </div>
              </form>
            </div>
          )}
          <div className="flex flex-col gap-4">
            <h5 className="text-2xl text-black font-display leading-[1.625rem] font-normal">
              {t('contact.customerServiceTitle')}
            </h5>
            <div className="flex flex-col gap-2">
              {data.customerServiceNumber ? (
                <div className="flex row min-w-none sm:min-w-[20rem] p-2 bg-[#FAFAFA] rounded-xl">
                  <Avatar className="bg-white text-secondary w-[2.875rem] h-[2.875rem]">
                    <PhoneIcon className="w-6 h-6" />
                  </Avatar>
                  <p className="flex items-center ml-4 text-[0.9375]">
                    <a className="text-black" href={`tel:${data.customerServiceNumber}`}>
                      {data.customerServiceNumber}
                    </a>
                  </p>
                </div>
              ) : null}
              {data.customerServiceEmail ? (
                <div className="flex row min-w-none sm:min-w-[20rem] p-2 bg-[#FAFAFA] rounded-xl">
                  <Avatar className="bg-white text-secondary w-[2.875rem] h-[2.875rem]">
                    <EmailIcon className="w-6 h-6" />
                  </Avatar>
                  <p className="flex items-center ml-4 text-[0.9375]">
                    <a className="text-black" href={`mailto:${data.customerServiceEmail}`}>
                      {data.customerServiceEmail}
                    </a>
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
