'use client';

import Button from '@mui/material/Button';
import { StoreLocator as StoreLocatorInput } from 'ui/components';
import { useState } from 'react';
import { LocalShippingOutlined } from '@mui/icons-material';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';
import { CartIcon, LocationIcon, PadlockIcon } from '@public/icons';
import Section from './section';
import BuyNowModal from './buy-now-modal';
import BuyMedicineModal from './buy-medicine-modal';

type Props = {
  data?: {
    _type?: string;
    brand?: string;
    buyNowTargetUrl: string;
    countryCode: string;
    headline?: string;
    variant?: 'compact' | 'regular';
  };
  locale: string;
  isFromPdP?: boolean;
  isMedicine?: boolean;
  translationsDictionary: TranslationsDictionary;
  ctaText?: string;
  redirects: {
    label: string;
    href: string;
    showCoupon?: boolean;
    image: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
  }[];
};

function StoreLocatorInputs({
  data,
  locale,
  translationsDictionary,
  isFromPdP,
  isMedicine,
  redirects,
  ctaText,
}: Props): JSX.Element | null {
  const t = useTranslation(translationsDictionary);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModal = (): void => {
    if (isFromPdP && redirects.length === 1) {
      window.open(redirects[0]?.href, '_blank', 'noopener,noreferrer');
      return;
    }

    setIsModalOpen((prevState) => !prevState);
  };

  if (!data) {
    return null;
  }

  const isCompact = data.variant === 'compact';

  const renderModal = (): JSX.Element | null => {
    if (!isModalOpen) return null;

    if (isFromPdP && redirects.length > 1) {
      return (
        <BuyMedicineModal
          ctaText={ctaText}
          onClose={handleModal}
          redirects={redirects}
          translationsDictionary={translationsDictionary}
        />
      );
    }
    return (
      <BuyNowModal
        buyNowURL={data.buyNowTargetUrl}
        onClose={handleModal}
        translationsDictionary={translationsDictionary}
      />
    );
  };

  return (
    <div className="flex gap-x-8 flex-col justify-center items-center w-full max-w-screen-md">
      {data.headline ? (
        <h3 className="w-full pb-0 md:pb-1 font-display text-[1.75rem] lg:text-[2.375rem] text-white leading-9 lg:leading-10 text-center">
          {data.headline}
        </h3>
      ) : null}
      <div
        className={`flex flex-col sm:flex-row w-full justify-center  ${
          isCompact ? 'items-baseline gap-x-2 xl:gap-x-6 gap-y-2' : 'max-w-[46.25rem] items-center gap-x-6 gap-y-4'
        }`}
      >
        <div className="w-full">
          <div
            className={`flex flex-col items-center w-full sm:py-4 ${
              isCompact
                ? `bg-white ${isMedicine ? 'rounded-lg' : 'rounded-t-lg'} sm:rounded-lg px-3 pb-5 pt-4`
                : 'px-2.5 pt-4 rounded-lg'
            }`}
          >
            <div
              className={`flex items-center justify-center w-full text-sm text-nowrap ${
                isCompact ? 'text-black' : 'text-white lg:text-lg'
              }`}
            >
              {isFromPdP ? (
                <PadlockIcon className="h-4 w-4 md:h-5 md:w-5 mr-1.5 text-nowrap" />
              ) : (
                <LocalShippingOutlined className="h-4 w-4 md:h-5 md:w-5 mr-1.5 text-nowrap" />
              )}
              {t('storeLocator.buyOnline')}&nbsp;<strong className="font-medium">{t('storeLocator.secure')}</strong>
            </div>
            <Button
              className={`mt-2 md:mt-3 whitespace-nowrap w-full max-w-[16.75rem] sm:max-w-full ${isCompact ? '' : ''}`}
              color={isCompact ? 'primary' : 'secondary'}
              disableElevation
              onClick={handleModal}
              size={isCompact ? 'small' : 'large'}
              startIcon={<CartIcon className="w-5 h-5" />}
              variant="contained"
            >
              {ctaText || t('storeLocator.buyNow')}
            </Button>
          </div>
        </div>
        <div
          className={`flex flex-col w-full rounded-lg sm:py-4 ${isCompact ? 'px-3 pb-5 pt-4 bg-white' : 'px-2.5 pb-4'}`}
        >
          <div
            className={`flex items-center justify-center w-full text-center text-sm text-nowrap ${isCompact ? 'text-black sm:text-left' : 'text-white lg:text-lg'}`}
          >
            <LocationIcon className="h-4 w-4 md:h-5 md:w-5 mr-1.5" />
            {t('storeLocator.findUs')}&nbsp;<strong>{t('storeLocator.closestPharmacy')}</strong>
          </div>
          <div className={`flex justify-center ${isCompact ? 'px-2 sm:px-0' : ''}`}>
            <StoreLocatorInput
              backgroundColor="bg-secondary"
              brand="fuca"
              buttonClassname={`hover:text-white focus-visible:shadow-none focus-visible:bg-primary focus-visible:text-white focus-visible:rounded-none ${isCompact ? 'bg-primary text-white' : 'bg-accent-4 text-primary'}`}
              className={`max-w-[16.75rem] mt-2 md:mt-3 sm:max-w-full ${isCompact ? 'h-10 md:h-11 border border-primary' : 'h-11 md:h-14 border-none'}`}
              countryCode={locale}
              inputAriaLabel={t('storeLocator.inputAriaLabel')}
              inputClassname="text-[#404040] text-sm sm:text-base"
              noLocationsFoundMsg={t('storeLocator.noLocationsFoundMsg')}
              noOptionsText={t('storeLocator.noOptionsText')}
              pinBackgroundColor="#739A30"
              placeholder={t('storeLocator.placeholder')}
              searchButtonAriaLabel={t('storeLocator.searchButtonAriaLabel')}
              textColor="text-secondary"
              title={t('storeLocator.modalTitle')}
              useMyLocationText={t('storeLocator.useMyLocationText')}
              variant={data.variant}
            />
          </div>
        </div>
      </div>
      {renderModal()}
    </div>
  );
}

export default function StoreLocator({
  data,
  locale,
  isFromPdP,
  isMedicine,
  redirects,
  translationsDictionary,
  ctaText,
}: Props): JSX.Element {
  if (data?.variant === 'compact') {
    return (
      <StoreLocatorInputs
        ctaText={ctaText}
        data={data}
        isFromPdP={isFromPdP}
        isMedicine={isMedicine}
        locale={locale}
        redirects={redirects}
        translationsDictionary={translationsDictionary}
      />
    );
  }

  return (
    <Section bgColor="bg-secondary">
      <StoreLocatorInputs
        data={data}
        isFromPdP={isFromPdP}
        isMedicine={isMedicine}
        locale={locale}
        redirects={redirects}
        translationsDictionary={translationsDictionary}
      />
    </Section>
  );
}
