import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Image from 'next/image';
import type { TranslationsDictionary } from '@lib/i18n';
import { useTranslation } from '@lib/i18n';
import { urlForImage } from '@lib/sanity/image';
import Coupon from './coupon';

type BuyMedicineModalProps = {
  onClose: () => void;
  translationsDictionary: TranslationsDictionary;
  ctaText?: string;
  redirects: {
    label: string;
    href: string;
    showCoupon?: boolean;
    image: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
  }[];
};

export default function BuyMedicineModal({
  onClose,
  translationsDictionary,
  redirects,
  ctaText,
}: BuyMedicineModalProps): JSX.Element | null {
  const t = useTranslation(translationsDictionary);
  return (
    <Dialog
      aria-describedby="buy-now-dialog-description"
      aria-labelledby="buy-now-dialog-title"
      onClose={onClose}
      open
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '350px',
          '@media (min-width:600px)': {
            maxWidth: '635px',
          },
        },
      }}
    >
      <IconButton
        aria-label={t('header.closeMenu')}
        className="text-black absolute top-0 right-0 p-1 sm:p-3"
        onClick={onClose}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <DialogTitle
        className="mt-3 sm:mt-6 w-full text-nowrap text-center font-medium text-[1.75rem] sm:text-3xl leading-9"
        id="buy-now-dialog-title"
      >
        {t('buyMedicineModal.title')}
      </DialogTitle>
      <DialogContent className="flex items-center justify-center text-center text-[0.9375rem] sm:text-base font-light leading-6 px-6 sm:px-10">
        <div className="pt-0">
          <p>{t('buyMedicineModal.description')}</p>
          <p className="mt-2">
            {t('buyMedicineModal.recommendationText')}
            <a href={t('buyMedicineModal.legalURL')}>{t('buyMedicineModal.legal')}</a>
            {t('buyMedicineModal.and')} <a href={t('buyMedicineModal.termsUrl')}>{t('buyMedicineModal.terms')}</a>{' '}
            {t('buyMedicineModal.last')}
          </p>
        </div>
      </DialogContent>
      <DialogActions className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-x-14 px-6 mb-6 grow">
        {redirects.map(({ href, image, label, showCoupon }) => (
          <div className="flex flex-col grow items-center h-full" key={label}>
            <Image alt={label} height={25} src={urlForImage(image).url()} width={150} />
            <Button
              className="whitespace-nowrap text-white mt-3 py-3.5 sm:py-3 text-base leading-6 font-semibold"
              color="primary"
              disableElevation
              href={href}
              startIcon={<ShoppingCartOutlinedIcon className="w-6 h-6" />}
              target="_blank"
              variant="contained"
            >
              {ctaText || t('storeLocator.buyNow')}
            </Button>
            {showCoupon ? (
              <div className="mt-3">
                <Coupon translationsDictionary={translationsDictionary} />
              </div>
            ) : null}
          </div>
        ))}
      </DialogActions>
    </Dialog>
  );
}
