import { PortableText } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types';
import { urlForImage } from '@lib/sanity/image';

type Props = {
  data: {
    _type: string;
    items?: {
      _key: string;
      description: PortableTextBlock;
      icon?: {
        asset: {
          _type: string;
          _ref: string;
        };
        lqip: string;
      };
      name: string;
    }[];
    title: string;
  };
};

export default function TextAndIconsVertical({ data }: Props): JSX.Element | null {
  return (
    <div className="flex flex-col md:flex-row w-full justify-center items-center px-4 md:px-10 lg:px-16 py-10 sm:py-12 lg:py-16 bg-white">
      <div className="flex gap-x-8 flex-wrap justify-center items-start sm:flex-nowrap w-full max-w-screen-lg">
        <div className="w-full sm:w-[22%] min-w-[14rem] pb-6">
          <div className="text-2xl lg:text-[2rem] text-black font-display leading-[1.875rem] lg:leading-10">
            {data.title}
          </div>
        </div>
        <ul className="grid grid-cols-1 flex-1 grow gap-x-4 gap-y-6 sm:gap-y-6">
          {data.items?.map((item) => (
            <li className="flex" key={item._key}>
              <img
                alt={item.name}
                className="min-w-20 h-20 sm:w-24 sm:min-w-24 sm:h-24"
                src={item.icon ? urlForImage(item.icon).url() : ''}
              />
              <div className="flex flex-col grow lg:justify-center pl-3 text-sm text-black">
                <p className="font-medium leading-5 lg:text-[0.9375rem]">{item.name}</p>
                <div className="font-light">
                  <PortableText value={item.description} />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
