'use client';

import Link from 'next/link';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { PortableText } from '@portabletext/react';
import { MedicineTag, NewTag } from '@public/icons';
import { urlForImage } from '@lib/sanity/image';
import { useTranslation } from '@lib/i18n';
import type { TranslationsDictionary } from '@lib/i18n';
import type { Product } from './featured-products';
import { MedicalInformationInfo } from './medicine-information';

type Props = {
  products?: Product[];
  translationsDictionary: TranslationsDictionary;
};

const productTypes = {
  medicine: 'product.medicine',
  supplement: 'product.supplement',
  sanitary: 'product.sanitary',
};

export default function ProductsGrid({ products, translationsDictionary }: Props): JSX.Element {
  const t = useTranslation(translationsDictionary);

  if (!products || products.length === 0) {
    return <div className="hidden">No products have been associated to this component</div>;
  }

  return (
    <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-x-8 md:gap-x-12 lg:gap-x-[4rem] gap-y-6 md:gap-y-12 lg:gap-y-[3.25rem] max-w-screen-lg">
      {products.map((product) => {
        const productTranslation = productTypes[product.productType];
        const currentProductUrl = `/${t('products.url')}/${product.slug?.current}`;
        const isMedicine = product.productType === 'medicine';

        return (
          <Card className="w-full max-w-[30rem]" elevation={0} key={product.name}>
            <Link className={`block rounded-lg ${isMedicine ? 'bg-accent-3' : 'bg-accent-2'}`} href={currentProductUrl}>
              <CardMedia
                className="relative bg-contain h-[clamp(17rem,25vw,16.25rem)]"
                image={product.image ? urlForImage(product.image, 'png').url() : ''}
                title={product.name}
              >
                {isMedicine ? (
                  <>
                    <div className="absolute flex items-center top-2 left-2 space-x-2 lg:space-x-4">
                      <MedicineTag className="w-[35px] h-[35px] lg:w-[40px] lg:h-[40px]" />
                      <span className="text-sm lg:text-sm leading-4 text-blue">{t('product.medicine')}</span>
                    </div>
                    <div className="absolute bottom-0 left-0 w-full">
                      <MedicalInformationInfo dict={translationsDictionary} />
                    </div>
                  </>
                ) : null}
                {Boolean(product.isNew && product.productType !== 'medicine') && (
                  <NewTag className="absolute lg:top-0 lg:left-0 w-[95px] h-[90px]" />
                )}
              </CardMedia>
            </Link>
            <CardContent className="px-0 pt-3 pb-2 lg:pb-3.5 text-center xs:text-left">
              <span
                className={`inline-block text-xs lg:text-sm leading-4 px-2.5 py-0.5 lg:py-1 lg:mb-[0.1875rem] text-nowrap rounded-md ${isMedicine ? 'text-blue bg-accent-3' : 'text-secondary bg-accent-2'}`}
              >
                {t(productTranslation)}
              </span>
              <h4 className="pb-1 font-display leading-[1.375rem] lg:leading-[1.875rem] line-clamp-3">
                <Link
                  className="text-black text-[1.375rem] lg:text-2xl font-semibold leading-7 lg:leading-[1.875rem] hover:no-underline"
                  href={currentProductUrl}
                >
                  {product.name}
                </Link>
              </h4>
              <div className="text-[0.8125rem] lg:text-sm text-black font-light leading-[1.125rem] lg:leading-5 line-clamp-3">
                <PortableText value={product.shortDescription ?? []} />
              </div>
            </CardContent>
            <CardActions className="flex px-0 pb-0 justify-center xs:justify-start">
              <Button
                color="primary"
                disableElevation
                href={currentProductUrl}
                sx={{
                  px: 5,
                  ...(isMedicine && {
                    backgroundColor: '#163FCB',
                    '&:hover': {
                      backgroundColor: '#014286',
                    },
                  }),
                }}
                variant="contained"
              >
                {t('productsCarousel.cta')}
              </Button>
            </CardActions>
          </Card>
        );
      })}
    </div>
  );
}
