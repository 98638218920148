import { PortableText } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types';
import { customSerializers } from '@lib/sanity/custom-sanity-serializers';

type Props = {
  data: {
    _type: string;
    content: PortableTextBlock;
    bgColor: string;
    width: 'full' | 'narrow';
  };
};

export default function TextBlock({ data }: Props): JSX.Element {
  return (
    <section className={`flex w-full justify-center px-4 md:px-10 py-10 lg:px-16 sm:py-12 lg:py-20 ${data.bgColor}`}>
      <div className={`block w-full wysiwyg ${data.width === 'narrow' ? 'max-w-screen-md' : 'max-w-screen-lg'}`}>
        <PortableText components={customSerializers} value={data.content} />
      </div>
    </section>
  );
}
