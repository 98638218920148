import { InstructionsIcon, MedicineIcon, PharmacistIcon } from '@public/icons';
import { useTranslation, type TranslationsDictionary } from '@lib/i18n';

export function MedicalInformationFooter({ dict }: { dict: TranslationsDictionary }): JSX.Element {
  const t = useTranslation(dict);

  return (
    <div className="flex justify-center items-center bg-medicine-footer p-3 gap-x-12">
      <div className="flex flex-col sm:flex-row items-center text-center">
        <InstructionsIcon className="w-8 h-8 sm:w-[40px] sm:h-[40px]" />
        <span className="text-white ml-2 text-xxs sm:text-xs">{t('medicalInformation.readInstructions')}</span>
      </div>
      <div className="flex flex-col sm:flex-row items-center text-center">
        <MedicineIcon className="w-8 h-8 sm:w-[40px] sm:h-[40px]" />
        <span className="text-white ml-2 text-xxs sm:text-xs">{t('medicalInformation.medicine')}</span>
      </div>
      <div className="flex flex-col sm:flex-row items-center text-center">
        <PharmacistIcon className="w-8 h-8 sm:w-[40px] sm:h-[40px]" />
        <span className="text-white ml-2 text-xxs sm:text-xs">{t('medicalInformation.pharmacist')}</span>
      </div>
    </div>
  );
}

export function MedicalInformationInfo({ dict }: { dict: TranslationsDictionary }): JSX.Element {
  const t = useTranslation(dict);

  return (
    <div className="flex justify-center items-center bg-medicine-footer p-2 rounded-b-lg">
      <InstructionsIcon className="w-4 h-4" />
      <span className="text-white mx-1 text-xxxs text-nowrap">{t('medicalInformation.readInstructions')}</span>
      <MedicineIcon className="w-4 h-4" />
      <span className="text-white mx-1 text-xxxs text-nowrap">{t('medicalInformation.medicine')}</span>
      <PharmacistIcon className="w-4 h-4" />
      <span className="text-white ml-1 text-xxxs text-nowrap">{t('medicalInformation.pharmacist')}</span>
    </div>
  );
}
