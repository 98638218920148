'use client';

import Link from 'next/link';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { urlForImage } from '@lib/sanity/image';
import { useTranslation } from '@lib/i18n';
import type { PostDocument } from '@lib/sanity/types';
import type { TranslationsDictionary } from '@lib/i18n';

type Props = {
  data?: {
    bgColor?: string;
    posts?: PostDocument[];
  };
  translationsDictionary: TranslationsDictionary;
};

export default function PostsGrid({ data, translationsDictionary }: Props): JSX.Element | null {
  const t = useTranslation(translationsDictionary);
  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-6 sm:py-8 lg:py-14 ${
        data?.bgColor ?? 'bg-white'
      }`}
    >
      <div className="flex flex-col w-full items-center px-4 md:px-10 lg:px-16">
        {data?.posts?.length ? (
          <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 gap-3 md:gap-5 lg:gap-6 max-w-screen-lg">
            {data.posts.map((post) => (
              <Card className="w-full max-w-[30rem] px-4 pt-3 pb-5 lg:p-5 rounded-lg" elevation={0} key={post.title}>
                <Link href={`/blog/${post.slug.current}`}>
                  <CardMedia
                    className="h-[11rem] sm:h-[16rem] lg:h-[12rem] rounded-lg"
                    image={urlForImage(post.mainImage).url()}
                    title={post.title}
                  />
                </Link>
                <CardContent className="min-h-[9rem] lg:min-h-[9.5rem] px-0 !pb-0">
                  <h4 className="pb-2 lg:pb-3">
                    <Link
                      className="text-2xl lg:text-[1.625rem] text-black font-display font-normal leading-[1.875rem] lg:leading-8 line-clamp-2 hover:no-underline"
                      href={`/blog/${post.slug.current}`}
                    >
                      {post.title}
                    </Link>
                  </h4>
                  <div className="text-sm text-black font-light leading-5 line-clamp-3">{post.excerpt}</div>
                </CardContent>
                <CardActions className="flex px-0 pb-0 justify-center xs:justify-start mt-2">
                  <Button
                    className="px-5"
                    color="primary"
                    disableElevation
                    href={`/blog/${post.slug.current}`}
                    variant="contained"
                  >
                    {t('post.readMore')}
                  </Button>
                </CardActions>
              </Card>
            ))}
          </div>
        ) : null}
      </div>
    </section>
  );
}
