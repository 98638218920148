'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import TextAndImage from './text-and-image';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

type Props = {
  data: {
    name: string;
    items?: {
      _type: string;
      bgColor: string;
      headline: string;
      subheadline: [];
      ctaText: string;
      ctaHref: string;
      image: {
        asset: {
          _type: string;
          _ref: string;
        };
        lqip: string;
      };
      variant: 'textFirst' | 'imageFirst';
      width: 'full' | 'narrow';
    }[];
  };
};

export default function TextAndImageCarousel({ data }: Props): JSX.Element | null {
  if (!data.items || data.items.length === 0) {
    return <div className="hidden">No data.items have been associated to this component</div>;
  }

  return (
    <section className="flex flex-col md:flex-row w-full justify-center items-center px-4 md:px-10 lg:px-16 bg-white">
      <Swiper
        breakpoints={{
          1024: {
            navigation: {
              enabled: true,
            },
          },
        }}
        className="w-full"
        loop={false}
        modules={[Pagination, Navigation]}
        navigation={{
          enabled: false,
        }}
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
      >
        {data.items.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <TextAndImage data={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
}
