'use client';

import { useState } from 'react';
import type { SyntheticEvent } from 'react';
import { PortableText, toPlainText } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { MinusCircleIcon, PlusCircleIcon } from '@public/icons';

type Props = {
  data?: {
    bgColor?: string;
    headline?: string;
    faqs?: {
      _id: string;
      question: string;
      answer: PortableTextBlock;
    }[];
  };
};

export default function Faqs({ data }: Props): JSX.Element | null {
  const [expanded, setExpanded] = useState<string | false>('');

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (!data?.faqs || data.faqs.length === 0) {
    return null;
  }

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: data.faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: toPlainText(faq.answer),
      },
    })),
  };

  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-6 sm:py-12 lg:py-[3.5rem] ${
        data.bgColor ? data.bgColor : 'bg-white'
      }`}
    >
      <script dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} type="application/ld+json" />

      <div className="flex w-full justify-center px-4 md:px-10 lg:px-16">
        <div className="flex gap-x-8 flex-col justify-center items-center w-full max-w-[49.75rem]">
          {data.headline ? (
            <h3 className="w-full pb-4 lg:pb-8 text-2xl lg:text-4xl text-black font-display font-normal leading-7 lg:leading-[2.875rem] text-left md:text-center">
              {data.headline}
            </h3>
          ) : null}
          {data.faqs.map((faq, index) => {
            const currentPanel = `panel${index}`;
            const isExpanded = expanded === currentPanel;
            return (
              <Accordion expanded={isExpanded} key={faq._id} onChange={handleChange(currentPanel)}>
                <AccordionSummary
                  aria-controls={`${currentPanel}-content`}
                  className="px-0 sm:px-4"
                  expandIcon={
                    isExpanded ? (
                      <MinusCircleIcon className="pr-3 w-8 h-5 text-primary" />
                    ) : (
                      <PlusCircleIcon className="pr-3 w-8 h-5 text-primary" />
                    )
                  }
                  id={`${currentPanel}-header`}
                >
                  <div className="w-full">{faq.question}</div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    paddingLeft: { lg: '3rem' },
                  }}
                >
                  <PortableText value={faq.answer} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </section>
  );
}
