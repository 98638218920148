import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import type { TranslationsDictionary } from '@lib/i18n';
import { useTranslation } from '@lib/i18n';

type Props = {
  translationsDictionary: TranslationsDictionary;
};

export default function ContactSuccessMessage({ translationsDictionary }: Props): JSX.Element {
  const t = useTranslation(translationsDictionary);
  return (
    <div className="flex flex-col items-center justify-center w-full sm:py-4 bg-accent-1 rounded-lg py-8 px-4 text-center">
      <CheckCircleOutlinedIcon className="w-8 h-8 text-[#6CC24A] " />
      <p className="text-lg font-semibold lg:font-medium lg:text-[1.375rem] text-black mt-2 leading-[1.625rem]">
        {t('contact.successMsg1')}
      </p>
      <p className="text-sm lg:text-[0.9375rem] text-black mt-2 leading-[1.625rem]">{t('contact.successMsg2')}</p>
    </div>
  );
}
