import CartIcon from './cart.svg';
import EmailIcon from './email.svg';
import FacebookIcon from './facebook.svg';
import InstagramIcon from './instagram.svg';
import InstructionsIcon from './instructions-icon.svg';
import LinkedInIcon from './linkedin.svg';
import LocationIcon from './location.svg';
import MedicineIcon from './medicine-icon.svg';
import MedicineTag from './medicine-tag.svg';
import MinusCircleIcon from './minus-circle.svg';
import NewTag from './new-tag.svg';
import PadlockIcon from './padlock.svg';
import PharmacistIcon from './pharmacist-icon.svg';
import PhoneIcon from './phone.svg';
import PlusCircleIcon from './plus-circle.svg';
import TruckIcon from './truck.svg';
import XIcon from './x.svg';
import YoutubeIcon from './youtube.svg';

export {
  CartIcon,
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  InstructionsIcon,
  LinkedInIcon,
  LocationIcon,
  MedicineIcon,
  MedicineTag,
  MinusCircleIcon,
  NewTag,
  PadlockIcon,
  PharmacistIcon,
  PhoneIcon,
  PlusCircleIcon,
  TruckIcon,
  XIcon,
  YoutubeIcon,
};
