import Image from 'next/image';
import { PortableText } from '@portabletext/react';
import { urlForImage } from '@lib/sanity/image';

type Props = {
  data: {
    _type: string;
    altText: string;
    headline?: [];
    subheadline?: [];
    alignment: string;
    largeImage: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
    mobileImage: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
  };
};

export default function Hero({ data }: Props): JSX.Element | null {
  const hasImage = data.headline || data.largeImage.asset._ref || data.mobileImage.asset._ref;
  if (!hasImage) {
    return null;
  }

  const largeImageUrl = urlForImage(data.largeImage).url();
  const mobileImageUrl = urlForImage(data.mobileImage).url();

  return (
    <section className="relative w-full bg-accent-3">
      <div className="relative w-full h-[50vw] min-h-[15rem] max-h-[32.8125rem]">
        <picture className="block relative w-full h-full">
          <source media="(max-width: 960px)" srcSet={mobileImageUrl} />
          <source media="(min-width: 1200px)" srcSet={largeImageUrl} />
          <Image
            alt={data.altText}
            blurDataURL={data.largeImage.lqip}
            fill
            placeholder="blur"
            priority
            sizes="100vw"
            src={largeImageUrl}
            style={{
              objectFit: 'cover',
            }}
          />
        </picture>
      </div>
      <div className="absolute inset-0 flex flex-col justify-between px-6 md:px-8 lg:px-16 pt-12 ">
        <div className="flex items-center h-full">
          <div className="w-full md:w-[516px] md:ml-auto lg:mr-32">
            {data.headline ? (
              <span className="text-left text-[1.75rem] leading-9 md:text-[3.875rem] md:leading-[4.25rem] font-display font-semibold text-white">
                <PortableText value={data.headline} />
              </span>
            ) : null}
          </div>
        </div>
        {data.subheadline ? (
          <div className="w-full text-left md:text-center pb-4 md:pb-0">
            <div className="pt-6 pb-2 md:py-4 text-xs md:text-sm text-white leading-4">
              <PortableText value={data.subheadline} />
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
}
