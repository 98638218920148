import { PortableText } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types';
import CardsCarousel from './cards-carousel';

type Props = {
  data: {
    _type: string;
    items?: {
      _key: string;
      cardTitle: string;
      description: PortableTextBlock;
      image?: {
        asset: {
          _type: string;
          _ref: string;
        };
        lqip: string;
      };
    }[];
    title: string;
    description: PortableTextBlock;
  };
};

export default function TextAndVerticalCards({ data }: Props): JSX.Element | null {
  return (
    <div className="flex flex-col md:flex-row w-full justify-center items-center px-4 md:px-10 lg:px-16 py-10 sm:py-12 lg:py-16 bg-white">
      <div className="flex gap-x-8 md:gap-x-10 flex-wrap justify-center items-start sm:flex-nowrap w-full max-w-screen-lg">
        <div className="w-full sm:w-[22%] min-w-[14rem] pb-6">
          <div className="text-2xl lg:text-[2rem] text-black font-display leading-[1.875rem] lg:leading-10">
            {data.title}
          </div>
        </div>
        <div className="w-full min-w-[14rem] sm:w-[84%] md:pl-8 text-[0.9375rem] leading-6">
          <PortableText value={data.description} />
          <div className="w-full mt-8">
            <CardsCarousel items={data.items} />
          </div>
        </div>
      </div>
    </div>
  );
}
